import axios from 'axios';
import { AxiosConfig } from '@watchtowerbenefits/shared-components';
import { config } from '@/utils/config.js';

const endpoint = (url) => `${config.VUE_APP_API_URL}/${url}`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => AxiosConfig.getConfig(cookieNamespace);

/**
 * Fetch quote requests for a given quote request id.
 *
 * @param {number} quoteRequestId - Quote Request Id.
 * @returns {Promise}
 */
export function getQuoteRequest(quoteRequestId) {
  return axios.get(endpoint(`marketing/quote_requests/${quoteRequestId}`), axiosConfig());
}
