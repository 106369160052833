<template>
  <div class="table">
    <div class="table-title">
      <h3>{{ title }}</h3>

      <AppButton
        v-if="title === 'Carrier team'"
        icon="fa-solid fa-plus"
        text="Invite teammate"
        data-test="invite-teammate"
        @click="$emit('open-invite-teammate')"
      />
    </div>
    <ElTable
      :data="users"
    >
      <template #empty>
        There are currently no {{ title.toLowerCase() }} contacts.
      </template>

      <ElTableColumn
        v-for="(column, index) in columns"
        :key="index"
        :prop="column.prop"
        :label="column.label"
        :sortable="column.sortable"
        :width="column.width"
      />
    </ElTable>
  </div>
</template>

<script>
  /**
   * ElTable component that displays provided users in a table
   *
   */
  export default {
    name: 'UserTable',
    props: {
      /**
       * carrier users or broker users table header
       */
      title: {
        type: String,
        required: true,
      },
      /**
       * users to display in the table
       */
      users: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        columns: [
          {
            prop: 'name',
            label: 'Name',
            sortable: true,
            width: 250,
          },
          {
            prop: 'email',
            label: 'Email',
            sortable: true,
          },
          {
            prop: 'company',
            label: 'Company',
          },
          {
            prop: 'dateAdded',
            label: 'Date added',
            sortable: true,
            width: 125,
          },
        ],
      };
    },
  };
</script>

<style scoped lang="scss">
  .el-table {
    margin-bottom: 30px;
  }

  /* stylelint-disable selector-max-compound-selectors */
  .table {
    border-bottom: none;
    padding: 20px;

    :deep .el-table th .cell {
      color: var(--tf-primary);
    }

    :deep .el-table .ascending .sort-caret.ascending {
      border-bottom-color: var(--tf-primary);
    }

    :deep .el-table .descending .sort-caret.descending {
      border-top-color: var(--tf-primary);
    }
  }
  /* stylelint-enable selector-max-compound-selectors */
  h3 {
    margin: 0;
  }

  .table-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
  }
</style>
