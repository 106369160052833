<template>
  <div
    v-if="isLoaded"
    class="contact-tables-container"
  >
    <ContactTable
      title="Carrier team"
      :users="carrierUsers"
      @open-invite-teammate="toggleInviteTeammateModal"
    />

    <ContactTable
      title="Broker team"
      :users="brokerUsers"
    />

    <InviteTeammateModal
      :visible.sync="showInviteTeammateModal"
      :project-id="projectId"
      :carrier-domains="carrierDomains"
      :existing-emails="existingEmails"
      @invite-teammate="handleInviteTeammate"
      @toggle-invite-modal="toggleInviteTeammateModal"
    />
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import { useAccountStore } from '@/stores/account.js';
  import projectService from '@/services/project.js';
  import ContactTable from '@/components/Contacts/ContactTable.vue';
  import InviteTeammateModal from '@/components/Modals/InviteTeammateModal.vue';

  /**
   * Displays ContactTable components for carrier/broker users under the contacts tab.
   *
   */
  export default {
    name: 'EventsContact',
    components: {
      ContactTable,
      InviteTeammateModal,
    },
    props: {
      /**
       * project that we are displaying users for
       */
      projectId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        showInviteTeammateModal: false,
        carrierId: null,
        carrierDomains: [],
        isLoaded: false,
        existingEmails: [],
      };
    },
    computed: {
      ...mapState(useAccountStore, ['userInfo']),
    },
    /**
     * Get contacts and related information from account  store.
     */
    async created() {
      await this.loadContacts();
      this.carrierId = this.userInfo.carrier_id;
      this.carrierDomains = this.userInfo.email_domains;
    },
    methods: {
      /**
       * Get carrier and broker users.
       */
      async loadContacts() {
        try {
          this.isLoaded = false;
          const {
            broker_users: brokerUsers,
            carrier_users: carrierUsers,
          } = await projectService.getCarrierContacts(this.projectId);

          this.carrierUsers = this.formatUsers(carrierUsers, 'carrier');
          this.brokerUsers = this.formatUsers(brokerUsers, 'broker');
          this.existingEmails = this.carrierUsers.map(({ email }) => email);
        } catch (error) {
          this.$message({
            showClose: true,
            message: 'Could not get contacts',
            type: 'error',
          });
          this.$router.push({ name: 'YourQuotes' });
        } finally {
          this.isLoaded = true;
        }
      },
      /**
       * Send carrier user project invitation.
       *
       * @param {string} email - Email of user to invite, emitted from invite teammate modal.
       */
      async handleInviteTeammate(email) {
        try {
          const response = await projectService.sendProjectInvitation(this.projectId, email, this.carrierId);
          const knownUser = response?.invitation.user_id;

          if (knownUser) await this.loadContacts();

          const successMessage = knownUser
            ? `${email} has been added to the carrier team and will receive opportunity updates.`
            : `${email} has been invited to ThreeFlow and will start receiving opportunity updates once their account setup is complete.`;

          this.$message({
            duration: 6000,
            message: successMessage,
            showClose: true,
            type: 'success',
          });
        } catch (error) {
          let errorMessage = error.response.data.message.email_address;

          if (errorMessage && errorMessage[0] === 'has already been taken') {
            errorMessage = `${email} has already been invited to this event.`;
          } else {
            errorMessage = 'There was an error inviting the user';
          }
          this.$message({
            duration: 6000,
            message: errorMessage,
            showClose: true,
            type: 'error',
          });
        }
      },
      /**
       * Toggle the visibility of the invite teammate modal.
       */
      async toggleInviteTeammateModal() {
        this.showInviteTeammateModal = !this.showInviteTeammateModal;
      },
      /**
       * Map over the user objects to return only what we need.
       *
       * @param {Array} users - The list of user.
       * @param {string} type - The type of users, either 'carrier' or 'broker'.
       * @returns {Array} The formatted user objects.
       */
      formatUsers(users, type) {
        return users.map(({ user, created_at: createdAt }) => ({
          name: user.full_name,
          company: type === 'carrier' ? user.carrier.name : user.broker.name,
          email: user.email,
          dateAdded: new Date(createdAt).toLocaleDateString('en-US'),
        }));
      },
    },
  };
</script>

<style scoped>
  .contact-tables-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
  }
</style>
