<script setup>
  import {
    ref, onBeforeMount, computed,
  } from 'vue';
  import { getQuoteRequest } from '@/services/medical.js';

  const props = defineProps({
    quoteRequestId: {
      type: String,
      required: true,
    },
  });
  const quoteRequest = ref(null);
  const selectedCoverageId = ref(null);
  const isLoading = ref(null);

  onBeforeMount(async () => {
    isLoading.value = true;
    try {
      const { data } = await getQuoteRequest(props.quoteRequestId);

      quoteRequest.value = data;
      selectedCoverageId.value = data.placement_event?.coverages[0].id;
    } catch {
      // handle error
    } finally {
      isLoading.value = false;
    }
  });

  const allCoverages = computed(() => quoteRequest.value?.placement_event?.coverages ?? []);
  /**
   * Used to populate the coverage select dropdown.
   */
  const coverageOptions = computed(() => allCoverages.value.map(({ id, name }) => ({
    id,
    name,
  })));
  const selectedCoverage = computed(() => allCoverages.value.find(({ id }) => id === selectedCoverageId.value));
  const brokerContact = computed(() => {
    // eslint-disable-next-line camelcase
    const { first_name, last_name, email } = quoteRequest.value?.requested_by ?? {};

    return {
      // eslint-disable-next-line camelcase
      name: `${first_name} ${last_name}`,
      email,
    };
  });
</script>

<template>
  <main>
    <div class="header">
      <ElSelect
        v-model="selectedCoverageId"
        data-test="coverage-select"
        placeholder="Loading..."
      >
        <ElOption
          v-for="{ id, name } in coverageOptions"
          :key="id"
          :data-test="`coverage-option-${name}`"
          :value="id"
          :label="name"
        >
          {{ name }}
        </ElOption>
      </ElSelect>
    </div>
    <div
      v-if="selectedCoverage"
      v-loading="isLoading"
      class="plan-info"
    >
      <PlanProvisions
        :plan-provisions="selectedCoverage?.plan?.provisions ?? []"
        data-test="plan-provisions"
      />
      <BenefitsAttributes
        id="container-medical-benefits"
        :benefits-attributes="selectedCoverage?.plan?.benefits ?? []"
        data-test="benefits-attributes"
      />
      <RatesTable
        id="container-medical-rates"
        :rate-attribute="selectedCoverage?.plan?.rates[0] || {}"
        data-test="rates-table"
      />
    </div>
    <div
      data-test="broker contact info"
      class="broker-contact"
    >
      <table aria-labeled-by="broker-contact-header">
        <tr>
          <td colspan="2">
            <h2 id="broker-contact-header">
              Broker contact
            </h2>
          </td>
        </tr>
        <tr class="header">
          <th>Name</th>
          <th>Email</th>
        </tr>
        <tr>
          <td>{{ brokerContact.name }}</td>
          <td>{{ brokerContact.email }}</td>
        </tr>
      </table>
    </div>
    <div class="quote-files">
      <!-- files table goes here -->
    </div>
  </main>
</template>

<style scoped>
  * {
    box-sizing: border-box;
  }

  main {
    padding: 2rem 4rem;
    height: fit-content;
    display: grid;
    /* stylelint-disable-next-line declaration-colon-space-after */
    grid-template:
      'header header' auto
      'plan-info broker-contact' auto
      'plan-info quote-files' 1fr / minmax(600px, 900px) minmax(350px, 1fr);
    grid-gap: 1rem;

    > .header {
      grid-area: header;

      .el-select {
        width: 400px;
      }
    }

    > .plan-info {
      grid-area: plan-info;
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

    > .broker-contact {
      grid-area: broker-contact;
    }

    > .quote-files {
      grid-area: quote-files;
    }
  }

  table {
    width: clamp(300px, 100%, 500px);
    border: 1px solid var(--tf-gray-medium);
    border-radius: 6px;

    tr {
      td, th {
        padding: 1rem;
        border-bottom: 1px solid var(--tf-gray-medium);
      }

      &:last-child {
        td, th {
          border-bottom: none;
        }
      }

      th {
        text-align: left;
        font-size: 16px;
        background-color: var(--tf-gray-light);
      }
    }
  }

  :deep(section) {
    /* sections inside plan info tables have an unneeded margin */
    /* spacing between tables is handled with flex gap above */
    /* stylelint-disable-next-line declaration-no-important */
    margin: 0 !important;
  }
</style>
